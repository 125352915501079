import { createTheme, ThemeProvider } from '@mui/material';
import React, { useReducer } from 'react';
import { useRoutes } from 'react-router-dom';
import { GlobalStylesComponent } from 'src/components/GlobalStyles';
import { routes } from 'src/routes';
import { GlobalAppContext } from './store/global-app-context';
import { initialState, reducer } from './store/reducer';
import { Typography } from './theme/typography';

export const App = () => {
  const routing = useRoutes(routes);
  const [state, dispatch] = useReducer(reducer, initialState);

  const theme: any = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: state.isDarkMode ? 'dark' : 'light',
          background: {
            // default: '#F4F6F8',
            // paper: state.isDarkMode ? '#212121' : '#fff'
          },
          primary: {
            // contrastText: '#ffffff',
            main: '#ED574A'
          },
          secondary: {
            main: '#191919'
          }

          // text: {
          //   primary: '#172b4d',
          //   secondary: '#6b778c'
          // }
        },
        components: {
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: state.isDarkMode ? '#212121' : '#fff'
              }
            }
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                borderRadius: 30
              }
            }
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: 10
              }
            }
          },
          // Not sure if this is a bug but I need to put this here to enable column sizes for
          // grid items
          MuiGrid: {
            styleOverrides: {
              root: {
                maxWidth: 'unset'
              },
              item: {
                maxWidth: 'unset'
              }
            }
          }
        },
        typography: Typography
      }),
    [state.isDarkMode]
  );

  return (
    <GlobalAppContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <GlobalStylesComponent />
        {routing}
      </ThemeProvider>
    </GlobalAppContext.Provider>
  );
};
