import {
  Button,
  Grid,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useState } from 'react';

export const EmailSubscribe = () => {
  const [value, setValue] = useState<string>('');
  const theme = useTheme();

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));
  return (
    <form
      action="https://soulart.us20.list-manage.com/subscribe/post?u=53dd3d23595d50c31174c7814&amp;id=c0b86be929"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <Grid
        id="mc_embed_signup_scroll"
        container
        direction={isMobile ? 'column' : 'row'}
        justifyContent="center"
        spacing={1}
      >
        <Grid item>
          <TextField
            placeholder="Your Email"
            variant="outlined"
            fullWidth
            sx={{ ...(!isMobile && { minWidth: 300 }) }}
            type="email"
            value={value}
            name="EMAIL"
            id="mce-EMAIL"
            onChange={(e) => setValue(e.target.value)}
            required
          />
        </Grid>
        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_53dd3d23595d50c31174c7814_c0b86be929"
            tabIndex={-1}
            value=""
            onChange={() => ''}
          />
        </div>
        <Grid item className="clear">
          <Button
            disabled={!validateEmail(value)}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            sx={{ height: '100%' }}
            type="submit"
            value="Subscribe"
            id="mc-embedded-subscribe"
          >
            Notify Me
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
