import { Navigate } from 'react-router-dom';
import { MainLayout } from 'src/components/MainLayout';
import { Home } from 'src/pages';

export const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Home /> },
      // { path: '/farm', element: <Farm /> },
      // { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/" /> }
    ]
  }
];
