import LogoSVG from 'src/assets/logos/soulart-logo.svg';

export const Logo = (props: any) => (
  <img
    alt="Logo"
    unselectable="on"
    style={{ pointerEvents: 'none', userSelect: 'none', MozUserSelect: 'none' }}
    draggable={false}
    src={LogoSVG}
    {...props}
  />
);
