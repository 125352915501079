import { Helmet } from 'react-helmet';
import { HomeLayout } from './HomeLayout';

export const Home = () => {
  const _description =
    'The heart and soul of Solana NFTs. A marketplace the Solana NFT community deserves with UI/UX focus. 0% fees when holding our genesis token!';

  return (
    <>
      <Helmet>
        <title>Coming Soon | SoulArt.io</title>
        <meta name="description" content={_description} />
      </Helmet>
      <HomeLayout />
    </>
  );
};
