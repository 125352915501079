import { SvgIcon } from '@mui/material';

export const RedGhost = (props: any) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1200"
    height="1046.3"
    viewBox="0 0 1200 1046.3"
    {...props}
  >
    <defs>
      <clipPath id="clip-Logo-Icon">
        <rect width="1200" height="1046.3" />
      </clipPath>
    </defs>
    <g id="Logo-Icon" clipPath="url(#clip-Logo-Icon)">
      <g
        id="Group_8219"
        data-name="Group 8219"
        transform="translate(-14667 -10261)"
      >
        <path
          id="Path_5718"
          data-name="Path 5718"
          d="M1124.079,1643.641S1178.958,1134.93,1650,1181.811c498.592,49.623,443.6,530.488,436.739,576.222s-47.248,420.73-479.8,434.452S819.965,1515.533,1124.079,1643.641Z"
          transform="translate(13672.903 9098.416)"
          fill="#fffdf9"
        />
        <path
          id="Path_5719"
          data-name="Path 5719"
          d="M1607.431,2222.148c-265.228.022-498.682-251.311-574.426-424.7-31.628-72.411-34.131-127.406-7.037-154.857,20.37-20.642,53.36-23.324,98.233-8.033,7.036-41.568,28.5-139.171,85.522-234.473,68.646-114.708,203.328-246.2,455.1-221.133,156.321,15.558,275.15,74.286,353.186,174.548,131.2,168.575,101.759,394.938,97.965,420.2l-.662,4.616c-13.066,92.186-42.652,172.936-87.941,240-87.33,129.326-224.166,197.833-406.7,203.623C1616.264,2222.076,1611.824,2222.148,1607.431,2222.148Zm-533.7-564.953c-10.958,0-19.329,2.709-24.7,8.155-16.409,16.62-11.169,62.271,13.667,119.13,39.637,90.745,120.371,193.561,210.693,268.319,78.419,64.914,202.122,141.252,346.267,136.758,171.544-5.44,299.691-69.152,380.875-189.367,42.514-62.961,70.342-139.138,82.713-226.418l.7-4.884c1.43-9.512,33.519-234.856-91.5-395.483-72.373-93-183.682-147.57-330.83-162.216-188.188-18.746-330.7,50.128-423.573,204.669-70.242,116.883-84.5,241.547-84.638,242.793a16.2,16.2,0,0,1-22.389,13.183C1107.914,1662.1,1088.479,1657.2,1073.728,1657.2Z"
          transform="translate(13659.681 9085.15)"
          fill="#191919"
        />
        <g
          id="Group_8216"
          data-name="Group 8216"
          transform="translate(15733.067 10606.116)"
        >
          <path
            id="Path_5720"
            data-name="Path 5720"
            d="M1201.888,1252.247s93.349-44.961,100.97,43.454-63.156,183.766-93.644,206.633C1209.214,1502.334,1235.012,1407.917,1201.888,1252.247Z"
            transform="translate(-1185.691 -1224.567)"
            fill="#fffdf9"
          />
          <path
            id="Path_5721"
            data-name="Path 5721"
            d="M1222.5,1531.859a16.2,16.2,0,0,1-15.625-20.47c.239-.878,24.23-93.131-7.542-242.443a16.193,16.193,0,0,1,8.816-17.961c5.468-2.636,54.473-25.12,90.868-4.395,19.541,11.142,30.732,31.678,33.263,61.048,8.3,96.291-68.218,197.093-100.062,220.979A16.177,16.177,0,0,1,1222.5,1531.859Zm11.27-256.194c16.309,83.13,16.176,148.06,12.649,190.034,28.163-38.569,58.478-98.6,53.588-155.28-1.563-18.128-7.292-30.131-17.032-35.683C1269.262,1266.922,1247.919,1271.065,1233.768,1275.666Z"
            transform="translate(-1198.976 -1237.895)"
            fill="#191919"
          />
        </g>
        <g
          id="Group_8217"
          data-name="Group 8217"
          transform="translate(14901.419 10597.862)"
        >
          <path
            id="Path_5722"
            data-name="Path 5722"
            d="M1310.113,1319.964s-185.974-120.432-242.377-67.077,56.4,199.7,114.33,234.756"
            transform="translate(-1036.183 -1223.454)"
            fill="#fffdf9"
          />
          <path
            id="Path_5723"
            data-name="Path 5723"
            d="M1195.33,1516.8a16.123,16.123,0,0,1-8.372-2.342c-48.648-29.447-129.354-127.868-136.9-198.311-3.477-32.512,8.861-51.7,19.824-62.071,63.813-60.347,242.182,52.208,262.313,65.247a16.2,16.2,0,0,1-17.6,27.188c-72.812-47.113-189.962-99.639-222.447-68.9-8.327,7.876-11.559,19.351-9.873,35.093,6.241,58.295,79.042,148.367,121.455,174.042a16.2,16.2,0,0,1-8.4,30.054Z"
            transform="translate(-1049.463 -1236.411)"
            fill="#191919"
          />
        </g>
        <g
          id="Group_8218"
          data-name="Group 8218"
          transform="translate(15380.067 10610.876)"
        >
          <path
            id="Path_5724"
            data-name="Path 5724"
            d="M1170.455,1290.2v70.365a51.45,51.45,0,0,0,102.9,0V1290.2a51.45,51.45,0,0,0-102.9,0Z"
            transform="translate(-976.099 -1238.751)"
            fill="#191919"
          />
          <path
            id="Path_5725"
            data-name="Path 5725"
            d="M1135.514,1290.2v70.365a51.447,51.447,0,1,0,102.894,0V1290.2a51.447,51.447,0,1,0-102.894,0Z"
            transform="translate(-1135.514 -1238.751)"
            fill="#191919"
          />
        </g>
        <ellipse
          id="Ellipse_334"
          data-name="Ellipse 334"
          cx="50.305"
          cy="93.75"
          rx="50.305"
          ry="93.75"
          transform="translate(15482.96 10862.05)"
          fill="#191919"
        />
      </g>
    </g>
  </SvgIcon>
);
