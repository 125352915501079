import { GlobalStyles, useTheme } from '@mui/material';
import BackgroundPNG from 'src/assets/images/background.png';

export const GlobalStylesComponent = () => {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',

          height: '100%',
          width: '100%'
        },
        body: {
          backgroundColor: theme.palette.background.paper,

          height: '100%',
          width: '100%',
          backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0.6) 100%), url(${BackgroundPNG})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed'
        },
        a: {
          textDecoration: 'none'
        },
        '#root': {
          height: '100%',
          width: '100%'
        }
      }}
    />
  );
};
