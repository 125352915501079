import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
  Card,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { SocialLinks } from 'src/assets/data';
import { RedGhost } from 'src/assets/ghosts/RedGhost';
import { DiscordIcon } from 'src/assets/icons/Discord';
import { EmailSubscribe } from 'src/components/EmailSubscribe';
import { Logo } from 'src/components/Logo';
import './ghost.css';

export const HomeLayout = () => {
  const theme = useTheme();

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Card
        sx={{
          // Safari issues with outline
          // outline: '2px solid #191919',

          borderRadius: '16px',
          backgroundClip: 'content-box',
          boxShadow: '0 0 0 2px #191919',

          mt: 3,
          mb: 3
        }}
        elevation={5}
      >
        <Logo style={{ paddingTop: 30, paddingLeft: 30 }} />
        <Grid
          container
          direction="column"
          justifyContent="center"
          spacing={2}
          sx={{ p: 3, maxWidth: 740 }}
        >
          <Grid item container direction="column" alignItems="center">
            <RedGhost
              style={{
                transform: 'translatey(0px)',
                width: 100,
                height: 100,
                animation: 'float 6s ease-in-out infinite'
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              color="textPrimary"
              textAlign="center"
            >
              Hey Solana
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              component="h1"
              color="textPrimary"
              textAlign="center"
              sx={{
                fontWeight: 1000
              }}
            >
              We are super excited to bring you a Marketplace you deserve.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              color="textPrimary"
              textAlign="center"
              sx={{ color: '#7D7C7A' }}
            >
              Stay tuned on our Twitter / Discord for more information and sign
              up below for email updates.
            </Typography>
          </Grid>
          <Grid item>
            <EmailSubscribe />
          </Grid>
        </Grid>
        <Grid
          sx={{
            backgroundColor: '#ED574A',
            width: '100%',
            borderTop: `2px solid ${theme.palette.secondary.main}`,
            borderRadius: '0px 0px 16px 16px',
            p: 3
          }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              color="secondary"
              href={SocialLinks.discord}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DiscordIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="secondary"
              href={SocialLinks.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="secondary"
              href={SocialLinks.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};
